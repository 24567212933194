<div class="hintContainer">
    <img
      class="close"
      src="../../../../assets/icons/closeIcon.svg"
      alt=""
      mat-button
      mat-dialog-close
    />
    <div class="top">
      <span class="title">
        <h1>{{ "modalID.title" | translate }}</h1>
        <img src="../../../../assets/icons/Frame.svg" alt="" />
      </span>
      <span class="text">
        <p class="t1">{{ "modalID.text1" |translate }}</p>
      </span>
      <span class="text">
        <p class="t2">{{ "modalID.text2" | translate }}</p>
        <p class="t2">
          {{ "modalID.text3" | translate }}
        </p>
      </span>
      <span class="flex">
        <div class="button">
   <button mat-button mat-dialog-close class="buttonClose">
          {{ "modalID.close" |translate }}
        </button>
        </div>
     
      </span>
    </div>
  </div>
  