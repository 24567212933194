<div class="header">
    <h2 class="text-center" matDialogTitle>{{ 'dialogLoginRegistSession.textSignupOrLogin' | translate }}</h2>
</div>
<mat-dialog-content class="mat-typography">
    <div class="text-center text-xl mt-4 ln-green">{{data.textHeader}}</div>
    <p class="text-center text-lg ln-green">{{ 'dialogLoginRegistSession.textCreate' | translate }} </p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="mbs-16">
    <div class="mx-w sm:mr-8 sm:mb-0 mb-8 mr-0 ">
        <button mat-raised-button (click)="goToLoginPage()" class="wider-button custom-button" [mat-dialog-close]="true">
            {{ 'header.linkLogin' | translate}}
        </button>
    </div>
    <div class="mx-w">
        <button mat-stroked-button color="primary" class="wider-button registBtn" (click)="goToRegisterPage()" [mat-dialog-close]="true">
            {{ 'dialogLoginRegistSession.textRegister' | translate}}
        </button>
    </div>
</mat-dialog-actions>