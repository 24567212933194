import {Injectable} from '@angular/core';
import {Locale} from '../../views/blog/core/interfaces/locale.interface';
import {map, Observable, tap} from "rxjs";
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private lang: string;

    constructor(private translate: TranslateService) {
        translate.addLangs(['en', 'es'])
        const lang = localStorage.getItem('language') || this.translate.getBrowserLang()  || 'es'
        this.lang = ['en', 'es'].includes(lang) ? lang : 'es';
        translate.use(this.lang);
        localStorage.setItem('language', this.lang)
    }

    addTranslation(...args: Locale[]): void {
        const locales = [...args];
        locales.forEach(locale =>
            this.translate.setTranslation(locale.lang, locale.data, true));
    }

    get getCurrentLang(): string {
        return this.lang;
    }

    async useLang(languageCode: string) {
        this.lang = languageCode;
       await this.translate.use(languageCode);
    }

    get onLangChange(): Observable<string> {
        return this.translate.onLangChange.pipe(
            map(({lang}) => lang),
            tap(lang => localStorage.setItem('language', lang))
        );
    }

    getTranslation(key: string): Observable<any> {
        return this.translate.get(key);
    }
}
