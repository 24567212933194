<div class="hintContainer">
    <img
      class="close"
      src="../../../../assets/icons/closeIcon.svg"
      alt=""
      mat-button
      mat-dialog-close
    />
    <div class="color-container">
        <div class="top">
            <span class="title">
              <h1>{{ "reservationPriceChanged.textTitle" | translate }}</h1>
              <img src="../../../../assets/icons/alertModal.svg" alt="" />
            </span>
            <span class="text">
              <p class="t1">{{ "reservationPriceChanged.text1" | translate }}</p>
            </span>
            <span class="flex flex-row">
                <p class="margin-horizontal">⦁ </p>
                <p class="t1">{{ "reservationPriceChanged.textOldPrice" | translate }}: ${{data.oldPrice}} {{ "reservationPriceChanged.textPerNight" | translate }}</p>
              </span>
              <span class="flex flex-row">
                <p class="margin-horizontal">⦁ </p>
                <p class="t3">{{ "reservationPriceChanged.textNewPrice" | translate }}: ${{data.newPrice}} {{ "reservationPriceChanged.textPerNight" | translate }}</p>
              </span>
              <span class="text">
                <p class="t1">{{ "reservationPriceChanged.text2" | translate }}</p>
              </span>
              <span class="text">
                <p class="t2">{{ "reservationPriceChanged.text3" | translate }}</p>
              </span>
          </div>
    </div>
    <span class="flex">
        <button mat-button mat-dialog-close class="buttonClose">
          {{ "reservationPriceChanged.textUnderstood" | translate }}
        </button>
      </span>
   
  </div>
  