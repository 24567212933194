import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Observable, take} from "rxjs";

import {AuthService} from "../../../services/auth.service";
import {DialogService} from "../../../services/dialog.service";
import {FavoritePostModel} from "../../../../core/domain/favorite-post-model";
//import {PropertyPostUseCaseService} from "../../../../core/usecase/property-post-use-case.service";
import {PropertyPostModel} from "../../../../core/domain/property-post-model";
import {ModalService} from "../../../services/modal.service";
import {FirebaseService} from "../../../services/firebase.service";
import {TranslateService} from "@ngx-translate/core";
import { AlojamientosPostUseCase } from '../../../../core/usecase/alojamientos-post-use-case.service';


@Component({
    selector: 'ln-card',
    templateUrl: './ln-card.component.html',
    styleUrls: ['./ln-card.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnCardComponent implements OnInit {

    @Input() post!: PropertyPostModel
    @Input() showDeleteOption = false;
    @Output() favoritePostDeleted = new EventEmitter<Boolean>();
    @Input() showDeactivatedPost = false;

    isFavorite = false;
    isLogin = this._firebaseService.isAuthenticatedUser();
    labelBathroom: Observable<string> = new Observable<string>();
    labelBedroom: Observable<string> = new Observable<string>();

    constructor(
        private _auth: AuthService,
        private _dialogService: DialogService,
        private _firebaseService: FirebaseService,
        private _postService: AlojamientosPostUseCase,
        private _router: Router,
        private _modal: ModalService,
        private translate: TranslateService
    ) {
    }

    ngOnInit() {
        this.isFavorite = this.post.like;
     
    }

    markAsFavorite(likeValue: boolean, deleted: boolean) {
        if (this._auth.isLogedin) {
           

            this.isFavorite = likeValue;

            this._postService.markAsFavorite(this.post.id)
                .pipe(take(1))
                .subscribe({
                    next: () => {
                        if (deleted)
                            this.favoritePostDeleted.emit(true);
                    },
                    error: () => {
                        this.isFavorite = !likeValue;
                    }
                });
        } else {
            this._dialogService.openLoginRegistSessionDialog()
                .pipe(take(1))
        }
    }

    openIframeDialog(path: string) {
        this._dialogService.openIframeDialog(path)
            .pipe(take(1))
    }

    goToRealState(id: string) {
        this._router.navigateByUrl(`real-state/${id}`)
    }

}
