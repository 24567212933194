import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

import {ModalInterface} from "../interfaces/modal.interface";
import {TranslationService} from "./translation.service";
import {TranslatePipe, TranslateService} from "@ngx-translate/core";
import {data} from "autoprefixer";


@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private modal = new BehaviorSubject<ModalInterface>({show: false})

    modal$ = this.modal.asObservable();

    constructor(private translateService: TranslateService) {
    }

    show(title1: string, description1: string, type: 'SUCCESS' | 'WARNING' | 'ERROR' = 'SUCCESS', duration: number = 3) {
        //console.log("test");
        let title, description = '';

        this.translateService.get(title1).subscribe(data => title = data )
        this.translateService.get(description1).subscribe(data => description = data )

        this.modal.next({show: true,  title , description, type})
        this._close(duration * 1000)
    }

    private _close(duration: number) {
        setTimeout(() => {
            this.modal.next({show: false, effect: true})
        }, duration)
    }

}
