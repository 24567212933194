import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AlojamientosRepository } from "../repositories/alojamientos-post.repository";
import { AlojamientosFilters } from "../../shared/interfaces/alojamientos-filters.interface";
import { AlojamientosResponseModel } from "../domain/mapa/alojamiento-filters-model";

@Injectable({
    providedIn: 'root'
})
export class AlojamientosPostUseCase{

    constructor(private repository: AlojamientosRepository) {}

    getAlojamientosConFiltros(filters?: AlojamientosFilters): Observable<AlojamientosResponseModel[]> {
      if (filters) {
          return this.repository.getAlojamientosByFilters(filters);
      } else {
          // Si no se proporcionan filtros, devolver un observable vacío
          return new Observable<AlojamientosResponseModel[]>(observer => {
              observer.next([]);
              observer.complete();
          });
      }
  }

    getAlojamientosCoordenadas(filters?: AlojamientosFilters): Observable<AlojamientosResponseModel[]> {
      if (filters) {
        const repository = this.repository.getAlojamientosCoordenadas(filters);
        return repository;
      } else {
        // Si no se proporcionan filtros, devolver un observable vacío
        return new Observable<any[]>(observer => {
          observer.next([]);
          observer.complete();
        });
      }
    }

      markAsFavorite(id: string): Observable<any> {
        return this.repository.markAsFavorite(id);
      }
}