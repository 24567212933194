import { Component } from '@angular/core';

@Component({
  selector: 'app-service-id',
  templateUrl: './service-id.component.html',
  styleUrl: './service-id.component.css'
})
export class ServiceIDComponent {

}
