<button
  mat-button
  [ngClass]="cardBooking"
  class="dateContainer"
  id="dateButton"
  [matMenuTriggerFor]="dateMenu"
  #trigger="matMenuTrigger"
>
  <span class="buttonText">{{ getButtonTextForCurrentLang() | translate }} <img *ngIf="!fromDate" class="calendarIcon" src="../../../../../assets/icons/calendar.svg" alt=""></span>
</button>

<mat-menu id="dateMenu" #dateMenu="matMenu" class="dateMenu" [overlapTrigger]="false" panelClass="dateMenu">
  <div class="calendar-container" (click)="$event.stopPropagation()">
    <div class="hintContainer pt-3 pb-3">
      <span class="pb-1">{{ "searchBar.textHint" | translate }}</span>
      <span>
		<span class="dates">{{ getButtonTextForCurrentLang() | translate }}</span>
		<span class="pl-2" *ngIf="nights>0">({{nights}} {{'searchBar.textNights'|translate}} )</span>
	</span>
    </div>
    <hr />
    <ngb-datepicker
      #dp
      [minDate]="today"
      [maxDate]="maxDate"
      [markDisabled]="isDisabled"
      [(ngModel)]="model"
      (dateSelect)="onDateSelection($event)"
      (navigate)="onNavigate($event)"
      outsideDays="hidden"
      [dayTemplate]="t"
    />

    <ng-template #t let-date="date" let-focused="focused" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled">
      <span
        class="custom-day"
        [class.in-range]="isInRange(date)" 
        [class.disabled]="disabled"
        [class.today]="isToday(date)"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        [class.outside-day]="date.month !== activeMonth"
        [class.range-start]="isRangeStart(date)"
        [class.range-end]="isRangeEnd(date)"

        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
      > 
        {{ date.day }}
      </span>
    </ng-template>

    <hr class="pb-3" />
    <div  *ngFor="let holiday of currentHolidays">
      <span class="holiday">
        {{ holiday.date | date : "dd/MM" }}: {{ holiday.name }}
      </span>
    </div>
  </div>
</mat-menu>
