import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";


export interface ModalInfoData {
    title: string;
    textHeader: string;
    textBody: string;
    buttonTextCancel: string;
    buttonText: string;
    showButtonCancel?: boolean;
}

@Component({
    selector: 'ln-dialog-info',
    templateUrl: './ln-dialog-info.component.html',
    styleUrls: ['./ln-dialog-info.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LnDialogInfoComponent {
    showCancel: boolean = true;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ModalInfoData,
        public dialogRef: MatDialogRef<LnDialogInfoComponent>
    ) {
        this.showCancel = !this.data.showButtonCancel;
    }

    cancel() {
        this.dialogRef.close(false);
    }

}
