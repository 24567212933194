<div class="petContainer">
  <img
    class="close"
    src="../../../../assets/icons/closeIcon.svg"
    alt=""
    mat-button
    mat-dialog-close
  />
  <div class="top">
    <span class="title">
      <h1>{{ "modalPets.title" | translate }}</h1>
      <img src="../../../../assets/icons/pets.svg" alt="" />
    </span>
    <span class="text">
      <p class="t1">{{ "modalPets.text1" | translate }}</p>
      <p class="t2">
        {{ "modalPets.text2" | translate }}
      </p>
    </span>
    <span class="flex">
      <button mat-button mat-dialog-close class="buttonClose">
        {{ "modalPets.close" | translate }}
      </button>
    </span>
  </div>
</div>
