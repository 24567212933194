import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { Descuentos, ResultadoDescuento } from "../interfaces/descuentos-interface";

/*
* Método para calcular el total en un alojamiento por noche
* si el alojamiento tiene descuento se aplica, se lo contratio devuelve el 
* precio por noche
* @param precioNoche: precio por noche
* @param descuento: si tiene se envia el monto d elo contrario se envia null
* @param cantidadNoches por defecto es 1 en caso de que sean mas se envia 
* parametro
*/
export function calcularTotalNoches(precioNoche: number, descuento: number | null, cantidadNoches: number = 1) {
  let total = precioNoche * cantidadNoches;

  if (descuento) {
    const precioOferta = precioNoche - (descuento * precioNoche / 100);
    total = precioOferta * cantidadNoches;
  }

  return total;
}

/** 
* Método para calcular el total de descuento por noches que tengan descuento.
* @param descuentos: Arreglo de descuentos con porcentaje de descuento y rango de fechas.
* @param precioPorNoche: Precio real por noche.
* @param rangoInicio: Fecha desde seleccionada por el usuario.
* @param rangoFin: Fecha hasta seleccionada por el usuario.
* @returns devuelve el el valor total de los dias con descuentos.
*/
export function calcularTotalConArrayDescuento(descuentos: any[], precioPorNoche: number, rangoInicio: string, rangoFin: string): ResultadoDescuento {
  let total = 0;
  let nochesTotales = 0;

  // Extraemos manualmente las partes de la fecha (YYYY-MM-DD)
  const [inicioAño, inicioMes, inicioDía] = rangoInicio.split('T')[0].split('-').map(Number);
  const [finAño, finMes, finDía] = rangoFin.split('T')[0].split('-').map(Number);

  // Construimos las fechas manualmente sin desfase de zona horaria
  const inicioRango = new Date(inicioAño, inicioMes - 1, inicioDía); // -1 porque los meses en JS son 0-indexed
  const finRango = new Date(finAño, finMes - 1, finDía);

  // Trabajamos siempre en UTC para evitar desfases de horas locales
  const inicioRangoUTC = new Date(Date.UTC(inicioRango.getUTCFullYear(), inicioRango.getUTCMonth(), inicioRango.getUTCDate()));
  const finRangoUTC = new Date(Date.UTC(finRango.getUTCFullYear(), finRango.getUTCMonth(), finRango.getUTCDate()));

  //console.log('Inicio rango UTC:', inicioRangoUTC);
  //console.log('Fin rango UTC:', finRangoUTC);

  const finRangoAjustado = new Date(finRangoUTC);
  finRangoAjustado.setDate(finRangoAjustado.getDate() - 1);

  descuentos.forEach((descuento) => {
    const [descuentoAñoInicio, descuentoMesInicio, descuentoDíaInicio] = descuento.fechaDesde.split('T')[0].split('-').map(Number);
    const [descuentoAñoFin, descuentoMesFin, descuentoDíaFin] = descuento.fechaHasta.split('T')[0].split('-').map(Number);

    const inicioDescuento = new Date(descuentoAñoInicio, descuentoMesInicio - 1, descuentoDíaInicio);
    const finDescuento = new Date(descuentoAñoFin, descuentoMesFin - 1, descuentoDíaFin);

    //console.log('Descuento desde sin desfase:', inicioDescuento);
    //console.log('Descuento hasta sin desfase:', finDescuento);

    // Verificar si hay intersección entre el rango de descuento y el rango proporcionado
    const inicioEfectivo = inicioDescuento >= inicioRango ? inicioDescuento : inicioRango;
    const finEfectivo = finDescuento <= finRango ? finDescuento : finRango;

    //console.log('Inicio efectivo sin desfase:', inicioEfectivo);
    //console.log('Fin efectivo sin desfase:', finEfectivo);

    if (inicioEfectivo <= finEfectivo) {
      // Calcular el número de noches de forma correcta
      const noches = Math.round((finEfectivo.getTime() - inicioEfectivo.getTime()) / (1000 * 60 * 60 * 24));
      nochesTotales += noches;

      const precioConDescuento = precioPorNoche * (1 - descuento.porcentajeDescuento / 100);
      total += precioConDescuento * noches;

       //console.log(`Aplicando descuento del ${descuento.porcentajeDescuento}% en ${noches} noche(s). Precio con descuento: ${precioConDescuento}`);
    } else {
       //console.log('No hay intersección de fechas, no se aplica descuento.');
    }
  });


  total = parseFloat(total.toFixed(2));

  return { total, nochesTotales };
}

export function contarNoches(fechaInicioStr: string, fechaFinStr: string): number {
  // Convertimos las fechas a objetos Date
  const fechaInicio = new Date(fechaInicioStr);
  const fechaFin = new Date(fechaFinStr);

  // Calculamos la diferencia en milisegundos entre las dos fechas
  const diferenciaTiempo = fechaFin.getTime() - fechaInicio.getTime();

  // Convertimos la diferencia de milisegundos a días
  const noches = diferenciaTiempo / (1000 * 60 * 60 * 24);

  return noches;
}

/**
 * Método para calcular el total a mostrar de un alojamiento 
 * tomando en cuenta las tarifas aplicadas
 * @param totalNoches monto total de noches
 * @param tarifaLunaNueva  tarifa de la plataforma
 * @param tarifaLimpieza  tarifa de limpieza
 * @returns devuelve sumatoria de montos sin aproximación
 */
export function calcularPrecioTotalConTarifas(totalNoches: number, tarifaLunaNueva: number, tarifaLimpieza: string | number) {
  tarifaLimpieza = typeof (tarifaLimpieza) === 'string' ? parseInt(tarifaLimpieza) : tarifaLimpieza;
  const suma = totalNoches + tarifaLimpieza + tarifaLunaNueva;
  return suma;
}

/***
 * Método para convertir timestamp a formato NgbDateStruct
 * @param timestamp: fecha en formato timestamp
 * @returns objeto NgbDateStruct
 */
export function timestampToNgbDateStruct(timestamp: string): NgbDateStruct {
  const date = new Date(Date.parse(timestamp));
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate() + 1
  }
}

/***
 * Método para convertir timestamp a formato NgbDateStruct
 * @param date: fecha en formato Date
 * @returns objeto NgbDateStruct
 */
export function dateToNgbDateStruct(date: Date): NgbDateStruct {
  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}


/***
 * Método para convertir NgbDateStruct a formato NgbDate
 * @param date: fecha en formato NgbDateStruct
 * @returns objeto NgbDate
 */
export function ngbDateStructToNgbDate(dateStruct: NgbDateStruct | null): NgbDate | null {
  if (dateStruct === null) {
    return null;
  }
  return new NgbDate(dateStruct.year, dateStruct.month, dateStruct.day);
}

/***
 * Método para convertir NgbDate a formato NgbDateStruct
 * @param date: fecha en formato NgbDate
 * @returns objeto NgbDateStruct
 */
export function ngbDateToNgbDateStruct(date: NgbDate | null): NgbDateStruct | null {
  if (date === null) {
    return null;
  }
  return { year: date.year, month: date.month, day: date.day };
}

/**
 * Validador personalizado para un campo de número de teléfono.
 * Este validador verifica que el número de teléfono tenga exactamente 8 dígitos,
 * ignorando cualquier otro carácter no numérico (como paréntesis, guiones, espacios, etc.).
 * 
 * @returns Un objeto de error con la clave `invalidPhone` si la validación falla, o `null` si la validación es exitosa.
 */

/**
* Validador personalizado para un campo de correo.
* Este validador verifica que el sea valido,
* Permite bloquear el dominio de algun servidor de correo no deseado
* 
* @returns Un objeto de error con la clave `forbiddenDomain` si la validación falla, o `null` si la validación es exitosa.
*/
export function emailDomainValidator(forbiddenDomain: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.value;
    if (email && email.includes('@')) {
      const domain = email.split('@')[1];
      if (domain.toLowerCase() === forbiddenDomain.toLowerCase()) {
        return { forbiddenDomain: true };
      }
    }
    return null;
  };
}