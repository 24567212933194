import { Component } from '@angular/core';

@Component({
  selector: 'app-service-pets',
  templateUrl: './service-pets.component.html',
  styleUrl: './service-pets.component.css'
})
export class ServicePetsComponent {

  
}
