<div class="stateContainer">
    <h4 class="location">{{location}}</h4>
    <div class="flexContainer">
        <p class="dates">{{startDate}} - {{endDate}}</p>
        <div *ngIf="state===RESERVATION_STATUS.CONFIRMED" class="greenStateColor innserStateContainer">
            <span class="state">{{'reservations.confirmed' | translate}}</span>
        </div>
        <div *ngIf="state===RESERVATION_STATUS.WAITING_CONFIRMATION" class="orangeStateColor innserStateContainer">
            <span class="state">{{'reservations.pending' | translate}}</span>
        </div>
        <div *ngIf="state===RESERVATION_STATUS.CANCELLED" class="redStateColor innserStateContainer">
            <span class="state">{{'reservations.cancelled' | translate}}</span>
        </div>
    </div>

</div>
