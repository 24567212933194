import {Injectable} from "@angular/core";
import {Observable, take} from "rxjs";
import {
    LnDialogInfoComponent
} from "../components/ln-customs-material-components/ln-dialog-info/ln-dialog-info.component";
import {MatDialog} from "@angular/material/dialog";
import {BreakPointService} from "./break-point.service";
import {
    LnDialogIframeComponent
} from "../components/ln-dialog-iframe/ln-dialog-iframe.component";
import {
    LnDialogLoginRegistSessionComponent
} from "../components/ln-dialog-login-regist-session/ln-dialog-login-regist-session.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private _breakPointService: BreakPointService,
        private _dialog: MatDialog
    ) {
    }

    openInfoDialog(
        buttonText: string,
        textHeader: string,
        textBody: string,
        title: string,
        buttonTextCancel?: string,
        dialogWidth: string = '571px'
    ): Observable<any> {
        return this._dialog
            .open(LnDialogInfoComponent, {
                width: this._breakPointService.isMobile ? '100%' : dialogWidth,
                disableClose: true,
                data: {
                    buttonText,
                    textHeader,
                    textBody,
                    title,
                    buttonTextCancel
                }
            })
            .afterClosed()
            .pipe(
                take(1)
            )
    }

    openIframeDialog(url: string): Observable<any> {
        return this._dialog
            .open(LnDialogIframeComponent, {
                maxWidth: '80vw',
                maxHeight: '80vh',
                disableClose: true,
                data: {url}
            })
            .afterClosed()
            .pipe(
                take(1)
            )
    }

    openLoginRegistSessionDialog(
        dialogWidth: string = '571px',
        currentRoute: string=''
    ): Observable<any> {
        return this._dialog
            .open(LnDialogLoginRegistSessionComponent, {
                width: this._breakPointService.isMobile ? '100%' : dialogWidth,
                disableClose: false,
                data: {
                    route:currentRoute
                }
            })
            .afterClosed()
            .pipe(
                take(1)
            )
    }
}
