import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import intlTelInput from "intl-tel-input";
import { UserEditUseCaseService } from "../../../../core/usecase/user-edir-use-case.service";
import { TranslationService } from "../../../services/translation.service";

@Component({
  selector: "ln-form-field-telephone",
  templateUrl: "./ln-form-field-telephone.component.html",
  styleUrls: ["./ln-form-field-telephone.component.css"],
})
export class LnFormFieldTelephoneComponent implements OnInit {
  @Input() control: FormControl = new FormControl<any>({});
  @Input() label = "Teléfono";
  @Input() error = "Campo requerido *";
  @Input() onlyNumbers = "Debe contener un código de área + 8 dígitos";
  @Input() hintText = '';

  telephonePrefix: string = "";

  constructor(private _editFormUseEditCaseService: UserEditUseCaseService, 
    private elementRef: ElementRef,
    private _translate: TranslationService,
  ) { }

  ngOnInit() {
    const input = document.getElementById("phone") as HTMLInputElement;
  
    if (input) {
      const telInput = intlTelInput(input, {
        initialCountry: "GT",
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.6/build/js/utils.js",
        separateDialCode: true,
        nationalMode: true,
      });
  
      this.telephonePrefix = telInput.getSelectedCountryData().dialCode;
      this._editFormUseEditCaseService.changeTelephonePrefix(this.telephonePrefix);


  
      // Limpia espacios del valor al actualizarse
      this.control.valueChanges.subscribe((value:any) => {
        if(typeof value=='string'){
                const cleanedValue = value.replace(/\s+/g, ''); // Elimina todos los espacios
        if (value !== cleanedValue) {
          this.control.setValue(cleanedValue, { emitEvent: false });
        }
        }
  
      });
  
      input.addEventListener("countrychange", () => {
        const selectedCountryData = telInput.getSelectedCountryData();
        this.telephonePrefix = selectedCountryData.dialCode;
        this._editFormUseEditCaseService.changeTelephonePrefix(this.telephonePrefix);
        this.adjustPrefixPosition();
      });

      const flagContainer = document.querySelector('.iti__flag-container') as HTMLElement;
      if (flagContainer) {
        flagContainer.addEventListener("click", () => {
          setTimeout(() => {
            const searchInput = document.querySelector('.iti__search-input') as HTMLInputElement;
            if (searchInput) {
              const lang = this._translate.getCurrentLang;
              if(lang=='es'){
                searchInput.placeholder = 'Buscar';
              }
              else{
                searchInput.placeholder = 'Search';

              }
             
            }
          }, 100);
        });
      }
    }
  }
  
  
  

  ngAfterViewInit(): void {
    this.adjustPrefixPosition();
  }

  adjustPrefixPosition(): void {
    const prefixElement = this.elementRef.nativeElement.querySelector('.telInput') as HTMLElement;
    if (prefixElement) {
      const prefixLength = this.telephonePrefix.length;
      const newLeft = 77 + (prefixLength * 5);
      prefixElement.style.paddingLeft = `${newLeft}px`;
    }
  }
}
