import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reservation-price-changed',
  templateUrl: './reservation-price-changed.component.html',
  styleUrl: './reservation-price-changed.component.css'
})
export class ReservationPriceChangedComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: { oldPrice: number, newPrice: number }) { }

}
