<div class="container-parent" (mouseleave)="showMessage=false">
  <div class="container">
    <div class="image-picker">
      <label class="upload-content" [ngClass]="{'error-border': isControlInvalid()}">
        <div class="custom-file-upload">
          <img src="../../../../assets/icons/iconIncrease.svg" class="icon" />
          <span class="custom-file-text">{{'register.textIdUpload'| translate}}</span>
          <input type="file" (change)="onFileSelected($event)" accept="image/*,application/pdf" />
          <img  src="../../../../assets/icons/field-alert.svg" class="iconAlert"
            (mouseover)="showMessage=true" />
        </div>
        <p *ngIf="showEye" class="eye-tooltip">{{'register.textSee' | translate}}</p>
        <p *ngIf="showDelete" class="close-tooltip">{{'register.textDelete' | translate}}</p>

        <div *ngIf="hasDocumentUrl || fileName" [ngClass]="{'hasDocumentUrl': hasDocumentUrl}" class="image-card">
          <span class="file-name">{{fileName}}</span>
          <img src="../../../../assets/icons/eyeBlack.svg" class="icon card-icon-eye" (click)="onImageOpen($event)"
            (mouseover)="showEye=true" (mouseleave)="showEye=false" />
          <img src="../../../../assets/icons/closeBlack.svg" class="icon card-icon-close"
            (click)="onImageDelete($event)" (mouseover)="showDelete=true" (mouseleave)="showDelete=false" />
        </div>

        <div *ngIf="uploading && percentLoaded !== 100" class="spinner">
          <span>{{'register.textUploading'| translate}}</span>
          <mat-progress-spinner diameter="30" [mode]="'determinate'"
          [value]="percentLoaded">></mat-progress-spinner>
          
        </div>

      </label>
    </div>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <div *ngIf="showMessage" class=" ml-4 tooltip border border-gray-200 rounded-md shadow-md z-100">
      <p class="text-tooltip text-sm text-white">{{ 'register.textHover' | translate }}</p>
      <u class="moreInfo text-white text-sm" (click)="showHint()">{{'card.textMoreInfo'|translate}}</u>
    </div>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
  </div>

  <p class="format" *ngIf="!isControlInvalid()">{{'register.textFormat' | translate}}.</p>
  <p class="error-text" *ngIf="isControlInvalid()">{{'register.textFieldRequired' | translate}}.</p>

</div>