import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { StoreFiltersService } from "./storeFilters.service";

@Injectable({
    providedIn: 'root'
})
export class IsPetService {
    private _petQuantity!: number;
    private hasEmittedTrue = false;
    pet=false
   constructor(private storeFilters: StoreFiltersService) {}

    get petQuantity(): number {
        return this._petQuantity;
    }
    set petQuantity(quantity: number) {
        this._petQuantity = quantity;
        this.emitPetSelected();
    }

    private petSelectedSubject = new Subject<boolean>();
    petSelected$ = this.petSelectedSubject.asObservable();

    private emitPetSelected(): void {
        //const actualType=this.storeFilters.getFilter().type
        if (this._petQuantity >= 1 && !this.hasEmittedTrue) {
            this.petSelectedSubject.next(true);
            this.hasEmittedTrue = true;
            this.pet=true
            // if(actualType==='BedBreakfast' ){
            //     this.storeFilters.deleteFilter('type');
            // }
        } else if (this._petQuantity === 0) {
            this.petSelectedSubject.next(false);
            this.hasEmittedTrue = false;
            this.pet=false
        }
    }
}