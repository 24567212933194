import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlojamientosRepository } from '../../core/repositories/alojamientos-post.repository';



@Injectable({
    providedIn: 'root'
})
export class PriceService {
    prices: any;
    pricesIntervals: any;

    hasPrices = false;
    constructor(private _alojamientosRepository: AlojamientosRepository) { }

    returnIntevals() {
        return this.pricesIntervals;
    }

    async getPrices(): Promise<any> {
      const pricesKey = 'pricesIntervals';
      const expirationKey = 'pricesExpiration';
      const expirationDays = 3; // Number of days before expiration
    
      const now = new Date().getTime();
      const expirationDate = sessionStorage.getItem(expirationKey);
      const storedPrices = sessionStorage.getItem(pricesKey);
    
      if (storedPrices && expirationDate && now < parseInt(expirationDate, 10)) {
        //console.log('Prices already loaded from session storage');
        this.pricesIntervals = JSON.parse(storedPrices);
        return Promise.resolve(this.pricesIntervals);
      } else {
        //console.log('Getting prices');
        return new Promise((resolve, reject) => {
          this._alojamientosRepository.getPrices().subscribe(
            (data) => {
              this.prices = data;
              this.hasPrices = true;
              // Extract price intervals and their counts
              const priceIntervals = this.prices.priceIntervals.map((interval: { interval: any[]; count: any; }) => ({
                price: (interval.interval[0] + interval.interval[1]) / 2,
                count: interval.count
              }));
    
              this.pricesIntervals = priceIntervals;
              //console.log('Prices loaded');
    
              // Save prices and expiration date in session storage
              sessionStorage.setItem(pricesKey, JSON.stringify(priceIntervals));
              const newExpirationDate = now + expirationDays * 24 * 60 * 60 * 1000; // Add expiration days in milliseconds
              sessionStorage.setItem(expirationKey, newExpirationDate.toString());
    
              resolve(priceIntervals); // Resolve the promise with the price intervals
            },
            (error) => {
              console.error('Error fetching prices:', error);
              reject(error); // Reject the promise if there is an error
            }
          );
        });
      }
    }
      
}