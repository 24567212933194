import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {
    signInAnonymously,
    Auth,
    onAuthStateChanged,
    UserCredential,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    User,
    signOut,
    fetchSignInMethodsForEmail
} from "@angular/fire/auth";
import {doc, getDoc, getFirestore} from "@angular/fire/firestore";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    get finalUrl() {
        return environment.firebaseVerify;
    }

    constructor(
        public auth: Auth,
        private http: HttpClient
    ) {
        auth.tenantId = environment.tenantId;
    }

    async initApp() {
        await this.loginFirebase();
        await this.getFirebaseClientId();
    }

    async loginFirebase() {
        const user = await this.isLoggedIn();
        if (!user) {
            await this.signInAnonymously();
        }
    }

    async signInAnonymously(): Promise<User> {
        return await signInAnonymously(this.auth)
            .then(({user}) => {
                this._saveUserInStorage(user)
                return user
            });
    }

    async signIn(email: string, password: string): Promise<User> {
        return await signInWithEmailAndPassword(this.auth, email, password)
            .then(({user}) => {
                this._saveUserInStorage(user)
                return user
            })
    }

    isLoggedIn(): Promise<User | null> {
        return new Promise((resolve) => {
            onAuthStateChanged(this.auth, (user) => {
                if (user) {
                    resolve(user)
                } else {
                    resolve(null)
                }
            }, () => resolve(null));
        });
    }

    getToken(): Promise<string> {
        return new Promise((resolve) => {
            onAuthStateChanged(this.auth, (user) => {
                if (user) {
                    user.getIdToken()
                        .then((token) => resolve(token))
                        .catch(() => resolve(''));
                } else {
                    this.auth.currentUser?.getIdToken(true)
                        .then(token => resolve(token))
                        .catch(() => resolve(''))
                }
            })
        })
    }

    async signUp(email: string, password: string): Promise<UserCredential> {
        return await createUserWithEmailAndPassword(this.auth, email, password);
    }

    async getFirebaseClientId() {
        const docRef = doc(getFirestore(), 'config', 'client-id');
        const docSnap = getDoc(docRef);
        const document = await docSnap;
        const client = document.data() ?? {};
        localStorage.setItem('client_id', client['client-id']);
    }

    verifyEmail(form: any) {
        return this.http.post(this.finalUrl, form);
    }

    async doLogout() {
        await signOut(this.auth);
        return this.signInAnonymously();
    }

    isAuthenticatedUser(): Observable<boolean> {
        return new Observable(observer => {
            onAuthStateChanged(this.auth, (user) => {
                if (!user || user?.isAnonymous) {
                    observer.next(false)
                } else {
                    observer.next(true)
                }
            })
        })
    }

    private _saveUserInStorage(user: User) {
        const token = user && (user as any)['accessToken']
        localStorage.setItem('token', token)
    }

    async deleteUser(user:any): Promise<void> {
        user = this.auth.currentUser;
        if (user) {
            await user.delete();
        }
    }

    async checkIfEmailExists(email: string): Promise<boolean> {
        try {
          const signInMethods = await fetchSignInMethodsForEmail(this.auth, email);
          // Si el array de métodos de inicio de sesión no está vacío, el email existe
          return signInMethods.length > 0;
        } catch (error) {
          console.error('Error comprobando el email: ', error);
          return false;
        }
      }
    

    async signOut() {
        await signOut(this.auth);
    }
    
}
