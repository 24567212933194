<div class="custom-card">
    <div style="overflow: hidden;">
        <div>
            <div style="width: 240px; height: 291px; background-color: white; border-radius: 20px;">
                <img class="closeIcon" id="closeIcon" src="../../../../assets/icons/closeIcon.svg" alt="Cerrar"
                    style="width: 25px; height: 25px; top: 10px; left: 10px; position: relative; color:#577778; cursor:pointer;">


                <img src="../../../../../assets/images/CardGenericaBeigeWeb.svg" *ngIf="data.imagenes.length===0"
                    id="imagen" alt="Habitación" (click)="navigate()"
                    style="width: 181px; height: 191px; object-fit: cover; object-position: bottom; margin-top: 5px; margin-left: auto; margin-right: auto; border-radius: 20px;">

                <img [src]="data.imagenes[0].url.sm" *ngIf="data.imagenes.length>0" id="imagen" alt="Habitación"
                    (click)="navigate()"
                    style="width: 181px; height: 191px; object-fit: cover; margin-top: 5px; margin-left: auto; margin-right: auto; border-radius: 20px;">
                <div    (click)="markAsFavorite()"
                    style="position: absolute; top: 2px; left: 5px; margin-top: 35px; margin-left: 165px; border-radius: 20px; width: 30px; height: 30px; display: flex;">
                    <img *ngIf="!isFavorite" src="../../../../assets/icons/favorite-post.svg"
                        style="border-radius: 15px; margin-left: 2px; width: 30px; cursor:pointer;">
                    <img *ngIf="isFavorite" src="../../../../assets/icons/favorite-post-like.svg"
                        style="border-radius: 15px; margin-left: 2px; width: 30px; cursor:pointer;">
                </div>
                <div
                    style="position: absolute; top: 2px; margin-top: 40px; background-color: white;  border-radius: 20px; margin-left:30px;width: fit-content; padding-right: 10px; max-width: 110px; height: fit-content; display: flex;">
                    <p
                        style="color:#372121; font-family: 'Roboto', sans-serif; font-weight: 500; font-size: 14px; margin-left: 10px; margin-top:auto; margin-bottom: auto;">
                    </p>
                </div>

                <div class="text">
                    <span class="flex  mt-2">
                        <img src="../../../../assets/icons/location-solid.svg" alt="Ubicación"
                            style="width: 21px; height: 21px;">
                        <h4
                            style="font-family: 'Roboto', sans-serif; font-size: 18px; line-height: normal; font-weight: 600; margin-left: 5px; width:140px; margin-bottom: 10px; overflow: hidden; white-space:nowrap; text-overflow: ellipsis; color:#372121 ;">
                            {{data.propiedad[0].direccion}}</h4>
                    </span>
                    <div class="flex">
                        <p *ngIf="data.propiedad[0].tipoPropiedad=== 'BedBreakfast'" class=" ml-3"
                            style="font-family: 'Roboto', sans-serif; font-size: 16px;  margin-bottom: auto;">
                            {{textBedBreakfast}} {{data.PrecioCalculado|currency}}</p>
                        <p *ngIf="data.propiedad[0].tipoPropiedad !== 'BedBreakfast'" class=" ml-3"
                            style="font-family: 'Roboto', sans-serif; font-size: 16px; margin-bottom: auto;">
                            {{textPrice}} {{data.PrecioCalculado|currency}}</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>