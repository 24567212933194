import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-succesful-reservation',
  templateUrl: './succesful-reservation.component.html',
  styleUrls: ['./succesful-reservation.component.css','./2succesful-reservation.component.css']
})
export class SuccesfulReservationComponent {

  isBedTypesEmpty: boolean = true;
  isBedAndBreakfast: boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA) public data:
   { imageUrl: string, totalCost: number, bedrooms: number, bathrooms: number, bedroomType: string,
     bathroomType: string, address: string, downtownDistance: number, accomodationType: string,
      bedTypes: any[], bbName:string, bbBuildingName:string }) {
    this.isBedTypesEmpty = this.checkBedTypes();
    this.isBedAndBreakfast=this.checkBB();
    }

    checkBedTypes(): boolean {
      return !this.data.bedTypes || this.data.bedTypes.length === 0;
    }

    checkBB():boolean{
      return (this.data.accomodationType.includes("Habitación") || this.data.accomodationType.includes("Room")); 
    }

}
