<div class="hintContainer">
    <img
      class="close"
      src="../../../../assets/icons/closeIcon.svg"
      alt=""
      mat-button
      mat-dialog-close
    />
    <div class="top">
      <span class="title">
        <h1>{{ "modalHints.title" | translate }}</h1>
        <img src="../../../../assets/icons/lunaNuevaFee.svg" alt="" />
      </span>
      <span class="text">
        <p class="t1">{{ "modalHints.text1" | translate }}</p>
      </span>
      <span class="flex">
        <button mat-button mat-dialog-close class="buttonClose">
          {{ "modalHints.close" | translate }}
        </button>
      </span>
    </div>
  </div>
  