import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserRepository} from "../repositories/user.repository";

@Injectable()

export class UserUseCaseService {

    constructor(private repository: UserRepository) {
    }

    get(): Observable<any> {
        return this.repository.get();
    }
    addPicturesToPost(filesNames: string): Observable<any> {
        return this.repository.addPicturesToPost( filesNames);
    }
    isFile(filesNames: string): Observable<any> {
        return this.repository.isFile(filesNames);
    }
    deleteFile(filesNames: string): Observable<any> {
        return this.repository.deleteFile(filesNames);
    }
    update(data: any): Observable<any> {
        return this.repository.update(data);
    }

    sendVerificationEmail(email: string): Observable<any> {
        return this.repository.sendVerificationEmail(email);
    }
}