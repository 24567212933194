<button mat-button class="priceContainer" [matMenuTriggerFor]="priceSlider" #activator>
  <span> {{ getButtonText()| translate }}</span>
</button>

<mat-menu #priceSlider="matMenu" class="priceSlider" panelClass="priceSlider">
  <div id="divMenu" class="divMenu" (click)=" $event.stopPropagation()">
    <h3 class="title">{{"searchBar.labelPriceRange"|translate}}</h3>
    <a class="subtitle">{{'searchBar.subtitle'|translate}}</a>
    <ng-container *ngIf="!loading; else loaderTemplate">

    <span class="chart">
      <div *ngFor="let result of results" [style.height.%]="result" [style.width.%]="width"
        [style.background-color]="getColor(result)" style="margin-bottom: 0; border-radius: 2px"></div>
    </span>
    </ng-container>

    <div class="col-span-2">
      <mat-slider [min]="minValue" [max]="maxValue" class="pr-3">
        <input [value]="0" matSliderStartThumb [ngModel]="startThumbValue" (ngModelChange)="changeInputStart($event)"
          (change)="emitValues()" />
        <input [value]="900000" matSliderEndThumb [(ngModel)]="endThumbValue" (ngModelChange)="changeInputEnd($event)"
          (change)="emitValues()" />
      </mat-slider>
    </div>

    <div class="inputs">
      <div class="mt-2.5 fieldContainer flex">
        <mat-label>{{ "rangePrice.from" | translate }}</mat-label>
        <mat-form-field class="bckColor" appearance="outline">
          <input id="startThumbValue" #startValue matInput placeholder="0"
            [ngModel]="startThumbValueInput | currency : 'USD' : 'symbol' : '1.0'"
            (ngModelChange)="changeRangeStart($event, startValue)" />
        </mat-form-field>
      </div>
      <div class="mt-2.5 fieldContainer flex">
        <mat-label>{{ "rangePrice.to" | translate }}</mat-label>
        <mat-form-field class="bckColor mt-5" appearance="outline">
          <input id="endThumbValue" #endValue matInput placeholder="0"
            [ngModel]="endThumbValueInput | currency : 'USD' : 'symbol' : '1.0'"
            (ngModelChange)="changeRangeEnd($event, endValue)" />
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-menu>


<ng-template #loaderTemplate>
  <div role="status" class=" border-gray-200 rounded shadow animate-pulse dark:border-gray-700">
    <div class="flex items-baseline">
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] dark:bg-gray-700"></div>
      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[20px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[20px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[50px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>

      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[20px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[20px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[50px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>

      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[20px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[20px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[50px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>

      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[20px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[50px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[40px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[20px] ms-1 dark:bg-gray-700"></div>
      <div class="w-full h-[50px] ms-1 bg-gray-200 rounded-t-lg dark:bg-gray-700"></div>
      <div class="w-full bg-gray-200 rounded-t-lg h-[30px] ms-1 dark:bg-gray-700"></div>
    </div>
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>