import { Component } from '@angular/core';

@Component({
  selector: 'app-service-hint',
  templateUrl: './service-hint.component.html',
  styleUrl: './service-hint.component.css'
})
export class ServiceHintComponent {

}
