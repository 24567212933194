<div class="text-xs">
    <button mat-raised-button
            class="close-button" (click)="dialogRef.close(true)"
    >
        <div class="center-div text-center">
            Cerrar <span matSuffix class="material-icons ml-2"> close </span>
        </div>
    </button>
</div>

<div class="iframe-container">
  <ln-iframe
        [url]="data?.url">
</ln-iframe>  
</div>

