import { Component } from '@angular/core';

@Component({
  selector: 'app-successful-contact-form',
  templateUrl: './successful-contact-form.component.html',
  styleUrl: './successful-contact-form.component.css'
})
export class SuccessfulContactFormComponent {

}
