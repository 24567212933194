import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { BreakPointService } from '../../../services/break-point.service';
import { MatMenu, MatMenuTrigger, MenuPositionY } from '@angular/material/menu';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { TranslateService } from '@ngx-translate/core';
import { AlojamientosRepository } from '../../../../core/repositories/alojamientos-post.repository';
import { PriceService } from '../../../services/price.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreFiltersService } from '../../../services/storeFilters.service';

@Component({
  selector: 'app-ln-price-slider',
  templateUrl: './ln-price-slider.component.html',
  styleUrls: [
    './ln-price-slider.component.css',
    './2ln-price-slider.component.css',
  ],
})
export class LnPriceSliderComponent implements OnInit {
  width = 100 / 45;
  defaultMinValue: number = 50;
  defaultMaxValue: number = 1000;
  minValue: number = 0;
  maxValue: number = 1000;
  values!: number[];
  count!: number;
  results: number[] = [];
  clicked: boolean = false;


  @Input() prices: any;
  @Input() startThumbValue: number = 0;
  @Input() startThumbValueInput: number = 0;
  @Input() endThumbValue: number = 1000;
  @Input() endThumbValueInput: number = 1000;
  @Output() selectedMinValueEvent = new EventEmitter<number>();
  @Output() selectedMaxValueEvent = new EventEmitter<number>();
  @Input() changedDefaultValues: boolean = false;
  @Input() valuesReceived: any = {};

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('matMenu') menu!: MatMenu;
  priceIntervals: any;
  loading: boolean = true;

  constructor(
    private currencyPipe: CurrencyPipe,
    private elementRef: ElementRef,
    private translate: TranslateService,
    private _priceService: PriceService,
    private _activatedRoute: ActivatedRoute,
    private _storeFilterService: StoreFiltersService
  ) { }


  async ngOnInit() {

    if (this.changedDefaultValues) {
      this.startThumbValueInput = this.valuesReceived['minPrice'];
      this.startThumbValue = this.valuesReceived['minPrice'];
      this.endThumbValueInput = this.valuesReceived['maxPrice'];
      this.endThumbValue = this.valuesReceived['maxPrice'];
    }
    await this.getPrices();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (this.trigger.menuOpen && !this.clicked) {
      this.trigger.closeMenu();
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside) {
    }
  }

  getPreviusPrices() {
    const filters = this._storeFilterService.getFilter();
    if (filters['minPrice'] || filters['maxPrice']) {
      this.startThumbValueInput = filters['minPrice'];
      this.endThumbValueInput = filters['maxPrice'];
      this.startThumbValue = filters['minPrice'];
      this.endThumbValue = filters['maxPrice'];
    }
    else  {
      this._activatedRoute.snapshot.queryParams['minPrice'] ? this.startThumbValueInput = this._activatedRoute.snapshot.queryParams['minPrice'] : this.startThumbValueInput = 50;
      this._activatedRoute.snapshot.queryParams['maxPrice'] ? this.endThumbValueInput = this._activatedRoute.snapshot.queryParams['maxPrice'] : this.endThumbValueInput = 1000;
      this.startThumbValue = this.startThumbValueInput;
      this.endThumbValue = this.endThumbValueInput;
      this._storeFilterService.setFilter({ minPrice: this.startThumbValueInput, maxPrice: this.endThumbValueInput });
    }

  }

  async getPrices() {
    try {
      this.getPreviusPrices();
      await this._priceService.getPrices();

      // Ahora espera a que la promesa de getPrices se resuelva
      if (this._priceService.pricesIntervals) {

        await this.calculateHeight(this._priceService.returnIntevals());
        this.loading = false;
      } else {
        console.error('Error fetching prices');
      }
    } catch (error) {
      console.error('Error fetching prices:', error);
    }
  }


  async calculateHeight(priceIntervals: { price: number, count: number }[]) {
    const maxCount = Math.max(...priceIntervals.map(interval => interval.count));

    // Calcular alturas
    this.results = priceIntervals.map(interval => ({
      price: interval.price,
      height: (interval.count / maxCount) * 100
    })).map(interval => interval.height);
  }



  @HostListener('document:click', ['$event'])
  onclick(event: any) {
    document.getElementById("startThumbValue")?.addEventListener("focusin", () => {
      this.clicked = true;
    });
    document.getElementById("startThumbValue")?.addEventListener("focusout", () => {
      this.clicked = false;
    });
    document.getElementById("endThumbValue")?.addEventListener("focusin", () => {
      this.clicked = true;
    });
    document.getElementById("endThumbValue")?.addEventListener("focusout", () => {
      this.clicked = false;
    });
  }

  emitValues() {
    this.selectedMinValueEvent.emit(this.startThumbValueInput);
    this.selectedMaxValueEvent.emit(this.endThumbValueInput);
    this.getButtonText()
  }

  changeInputStart($event: number) {
    this.startThumbValueInput = $event;
  }
  changeInputEnd($event: number) {
    this.endThumbValueInput = $event;
  }

  changeRangeStart($event: number, input: any) {
    this.startThumbValue = Number.parseFloat(
      $event.toString().replace(/[^0-9.]+/g, '')
    );

    if (Number.isNaN(this.startThumbValue)) {
      this.startThumbValue = 0;
    }

    this.startThumbValueInput = this.startThumbValue;
    input.value = this.currencyPipe.transform(
      this.startThumbValueInput,
      'USD',
      'symbol',
      '1.0'
    );
    this.emitValues();
  }

  changeRangeEnd($event: number, input: any) {
    this.endThumbValue = Number.parseFloat(
      $event.toString().replace(/[^0-9.]+/g, '')
    );

    if (Number.isNaN(Number(this.endThumbValue))) {
      this.endThumbValue = 0;
    }

    this.endThumbValueInput = this.endThumbValue;
    input.value = this.currencyPipe.transform(
      this.endThumbValueInput,
      'USD',
      'symbol',
      '1.0'
    );
    this.emitValues();
  }





  getColor(value: number): string {
    const startColor = [161, 180, 186]; // RGB for #A1B4BA
    const endColor = [87, 119, 120]; // RGB for #577778

    const maxResult = Math.max(...this.results);
    const ratio = value / maxResult;
    const color = startColor
      .map((start, i) => Math.round(start + ratio * (endColor[i] - start)))
      .join(',');

    return `rgb(${color})`;
  }

  getButtonText(): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    if (this.startThumbValueInput !== this.defaultMinValue || this.endThumbValueInput !== this.defaultMaxValue) {
      return ` ${formatter.format(this.startThumbValueInput)} - ${formatter.format(this.endThumbValueInput)} `;
    } else {
      return this.translate.instant('searchBar.placeHolderChooseRange');
    }

  }

}
