import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { UserEditRepository } from '../repositories/user-edit.repository ';
import { UserEditModel } from '../domain/user-edit-model';

@Injectable({
    providedIn: 'root'
})

export class UserEditUseCaseService {

    private telephonePrefixSource = new BehaviorSubject<string>('');
    currentTelephonePrefix = this.telephonePrefixSource.asObservable();

    constructor(private repository: UserEditRepository) {}

    post(form: Partial<UserEditModel>, captcha: string): Observable<any> {
        return this.repository.post(form, captcha);
    }

    changeTelephonePrefix(prefix: string) {
        this.telephonePrefixSource.next(prefix);
    }

}
