import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Swiper } from 'swiper';
import { Router } from '@angular/router';
import { carouseldataInterface } from '../../interfaces/carousel-publisher.interface';
import { environment } from '../../../../environments/environment';
import { connectStorageEmulator } from '@angular/fire/storage';



@Component({
  selector: 'ln-carousel-publisher',
  templateUrl: './ln-carousel-publisher.component.html',
  styleUrls: ['./ln-carousel-publisher.component.css', './ln-carousel-publisher2.components.css']
})
export class LnCarouselPublisherComponent implements OnInit, AfterViewInit {
  @Input() titulo: string = '';
  @Input() carouselData: any[] | null = [];;
  @Input() viewAllRedirect: string = '';
  @Input() redirectionDetail: string = '';
  srcEnv = environment.firebaseConfig.storageBucket + '/';
  swiper: Swiper | undefined;
  isData = false;

  constructor(
    private router: Router,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.carouselData && this.carouselData.length > 0) {
        this.isData = true;
        this._cdr.detectChanges(); // Forzar la detección de cambios
      }
      this.router.events.subscribe(event => {
        this.swiper?.update();
        this.swiper?.slideTo(0);
      });
    }, 200);
  }


  ngAfterViewInit(): void {
    this.swiperConfig();
  }

  swiperConfig() {

    this.swiper = new Swiper(".swiper", {
      direction: "horizontal",
      slidesPerView: "auto",
      cssMode: true,
      //revisar bullets extras
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      breakpoints: {
        "@0.00": {
          slidesPerView: "auto",
          centeredSlides: true,
          watchOverflow: true,
        },
        "@0.75": {
          slidesPerView: 2,
          centeredSlides: true,
          watchOverflow: true,
        },
        "@1.00": {
          slidesPerView: 2,
          watchOverflow: true,
          centerInsufficientSlides: true,
        },
        "@1.25": {
          slidesPerView: 3,
          centerInsufficientSlides: true,
          watchOverflow: true,

        },
        "@1.50": {
          slidesPerView: 4,
          centerInsufficientSlides: true,
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      resizeObserver: true,
      updateOnWindowResize: true,
    });

  }

  goToDetail(id: number) {
    this.router.navigateByUrl(`${this.redirectionDetail}/${id}`)
  }
  goToSeeAll() {
    this.router.navigateByUrl(this.viewAllRedirect);
  };


}
