import { Observable } from 'rxjs';
import { UserEditModel } from '../domain/user-edit-model';
import { Injectable } from '@angular/core';


//TODO preguntar si esto está bien acá
@Injectable({
    providedIn: 'root' 
  })
export abstract class UserEditRepository {
    abstract post(form: Partial<UserEditModel>, captcha: string): Observable<any>;
}
