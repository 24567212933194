import {inject, Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {take} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class BreakPointService {

    isMobile = false;

    private _size = inject(BreakpointObserver);

    constructor() {
        this._getViewSize();
    }

    private _getViewSize() {
        this._size.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge // Nueva regla para pantallas grandes
          ]).subscribe(result => {
            if (result.breakpoints[Breakpoints.XSmall] ||
                result.breakpoints[Breakpoints.Small]) {
              this.isMobile = true;
            } else {
              this.isMobile = false;
            }
          });
        }
}
