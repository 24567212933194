<div class="text-xs fieldInput inputRegister">
    <mat-label class="pb-2">{{ label | translate }}</mat-label>
    <mat-form-field appearance="outline">
        <input

                matInput
                [type]="type"
                [placeholder]="placeholder"
                [formControl]="control"
                [disabled]="isDisabled"
        />
        <mat-icon *ngIf="haveIcon" matPrefix>{{ icon }}</mat-icon>
        <mat-hint>{{ hintText | translate }}</mat-hint>
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgRequiredField' | translate}}
        </mat-error>
        <mat-error *ngIf="control.hasError('email') && control.touched" class="form-error-msg">
            {{ 'fieldInput.textInvalidEmailFormat' | translate }}
        </mat-error>
        <mat-error *ngIf="control.hasError('cuiInvalid') && control.touched" class="form-error-msg">
            {{ 'fieldInput.textInvalidDpiFormat' | translate }}
        </mat-error>
        <mat-error *ngIf="control.hasError('forbiddenDomain') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgYahooEmailNotAllowed' | translate }}
          </mat-error>
    </mat-form-field>
</div>
