<div class="text-xs divSelect inputRegister">
    <mat-label class="pb-2">{{ label | translate }}</mat-label>
    <mat-form-field appearance="outline">
        <mat-select [formControl]="control" (selectionChange)="select($event)">
            <mat-option [value]="item?.value" *ngFor="let item of list">{{ item.key | translate }}</mat-option>
        </mat-select>
        <mat-hint>{{ hintText | translate }}</mat-hint>
        <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
            {{ 'fieldInput.msgRequiredField' | translate }}
        </mat-error>
    </mat-form-field>
</div>