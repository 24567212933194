<div class="text-xs">
    <mat-label>{{ 'servicesForm.labelTelephone' | translate }}</mat-label>
    
    <div class="input-container">
      <input class="telInput" type="tel" id="phone" [formControl]="control">
      <p class="prefix" >+{{ telephonePrefix }}</p>
      <mat-hint *ngIf="!control.touched" class="form-label-msg" style="z-index: 0;">{{ hintText | translate }}</mat-hint>
      <mat-error *ngIf="control.hasError('required') && control.touched" class="form-error-msg">
        {{ 'fieldInput.msgRequiredField' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('pattern') && control.touched" class="form-error-msg">
        {{ 'fieldInput.msgOnlyNumbers' | translate }}
      </mat-error>
      <mat-error *ngIf="control.hasError('invalidPhone') && control.touched && !control.hasError('required')" class="form-error-msg">
        {{ 'fieldInput.msgOnlyNumbers' | translate }}
      </mat-error>
    </div>
  </div>
  
