import { Injectable } from '@angular/core';
import { AlojamientoFiltersModel } from '../domain/mapa/alojamiento-filters-model';
import { AlojamientosFilters } from '../../shared/interfaces/alojamientos-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private previousFilters: AlojamientosFilters= {};

  constructor() { }

  // Método para establecer los filtros antiguos
  setPreviousFilters(filters: AlojamientosFilters): void {
    this.previousFilters = filters;
  }

  // Método para obtener los filtros antiguos
  getPreviousFilters(): AlojamientosFilters {
    return this.previousFilters;
  }

}