import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {PublisherModel, PublisherModelList} from "../domain/publisher-model";
import {PublisherListInterface} from "../../shared/interfaces/publisher.interface";


@Injectable()
export abstract class PublisherRepository {
    abstract getPublisher(): Observable<PublisherModelList>;
    abstract get(): Observable<PublisherListInterface>;
    
}