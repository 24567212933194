import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";


@Component({
  selector: 'app-ln-dialog-login-regist-session',
  templateUrl: './ln-dialog-login-regist-session.component.html',
  styleUrls: ['./ln-dialog-login-regist-session.component.css']
})
export class LnDialogLoginRegistSessionComponent {

  constructor(
      public dialogRef: MatDialogRef<LnDialogLoginRegistSessionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {route:string, textHeader:string},
      private _router: Router,
  ) {
  }

  goToRegisterPage(): void {
    this._router.navigate(['/sessions/register'], { queryParams: { returnUrl: this.data.route } });
    this.dialogRef.close();
  }

  goToLoginPage(): void {
    this._router.navigate(['/sessions/signin'], { queryParams: { returnUrl: this.data.route } });
    this.dialogRef.close();
  }
}
