<div class="hintContainer">
    <img
      class="close"
      src="../../../../assets/icons/closeIcon.svg"
      alt=""
      mat-button
      mat-dialog-close
    />
    <div class="top">
      <span class="title">
        <h1>{{ "successfulContact.textTitle" | translate }}</h1>
      </span>
      <span class="text">
        <p class="t1">{{ "successfulContact.text1" |translate }}</p>
      </span>
      <span class="flex">
        <div class="button">
   <button mat-button mat-dialog-close class="buttonClose">
          {{ "modalID.close" |translate }}
        </button>
        </div>
     
      </span>
    </div>
  </div>
  