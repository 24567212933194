import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isLogedin = false;
    hasDocumentImage = false

    constructor(private _http: HttpClient) {
    }

    forgotPassword(email: string, captcha: string) {
        const headers = {
            'X-Recaptcha-Token': captcha
        }
        return this._http.post<any>(
            `${environment.baseURL}auth/reset-password`,
            { email }, { headers }
        )
    }

    async documentImage() {
        try {
            const documentURl = await this._http.get<any>(`${environment.baseURL}user-data/document-url`).toPromise();
            if (documentURl && documentURl.urlDocumentoIdentidad) {
                this.hasDocumentImage = true;
            } else {
                this.hasDocumentImage = false;
            }
            localStorage.setItem('hasImage', this.hasDocumentImage.toString());
        } catch (error) {
            console.error('Error fetching document URL:', error);
            this.hasDocumentImage = false;
            localStorage.setItem('hasImage', this.hasDocumentImage.toString());
        }
    }
}
