<div class="skeleton-grid">
   <ngx-skeleton-loader *ngFor="let i of [].constructor(6)" count="1" animation="progress" appearance="line" [theme]="{
         'display': 'flex',
         'height': '230px',
         'border-radius': '30px',
         'width': '100%',
         'justify-content': 'center',
         'gap': '1.5rem'
      }">
   </ngx-skeleton-loader>
 </div>