import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {PublisherRepository} from "../repositories/publisher.repository";
import { PublisherModel, PublisherModelList } from "../domain/publisher-model";
import { PublisherListInterface } from "../../shared/interfaces/publisher.interface";




@Injectable({
    providedIn: "root"
})
export class PublisherUseCaseService {
    

    constructor(
        private repository: PublisherRepository
    ) {
    }

    getPublisher(): Observable<PublisherModelList> {
        return this.repository.getPublisher()
    }
    get(): Observable<PublisherListInterface> {
        return this.repository.get()
    }
    
}