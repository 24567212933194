import {Observable} from 'rxjs';

export abstract class UserRepository {
    abstract get(): Observable<any>;
    abstract addPicturesToPost(filesNames: string): Observable<any> 
    abstract isFile(filesNames: string): Observable<any>
    abstract deleteFile(filesNames: string): Observable<any>
    abstract update(data: any): Observable<any>
    abstract sendVerificationEmail(email: string): Observable<any>
}
